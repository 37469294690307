<template>
  <div>
    <el-form ref="form" label-width="180rem" style="padding-top: 48rem">
      <el-form-item label="注册手机号">
        {{ data.mobile }}
      </el-form-item>
      <el-form-item label="家长姓名">
        {{ data.name }}
      </el-form-item>
      <el-form-item label="学生姓名">
        {{ data.student_name }}
      </el-form-item>
      <el-form-item label="家长和学生关系">
        {{ data.relationship }}
      </el-form-item>
      <el-form-item label="咨询学校">
        {{ data.school_name }}
      </el-form-item>
      <el-form-item label="咨询老师">
        {{ data.consulting_teacher }}
      </el-form-item>
      <el-form-item label="招收状态">
        {{ data.recruit_status + '' === '1' ? '可招收' : '不可招收' }}
      </el-form-item>
      <el-form-item label="是否产生订单">
        {{ data.exist_order + '' === '1' ? '是' : '否' }}
      </el-form-item>
      <h2 style="margin-left: 40rem;margin-bottom: 12rem">学生基本情况</h2>
      <el-form-item label="重大疾病">
        {{ data.physiology }}
      </el-form-item>
      <el-form-item label="心理健康状态">
        {{ data.psychology }}
      </el-form-item>
      <el-form-item label="犯罪前科">
        {{ data.crime }}
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="$router.back()">返回</el-button>
        <el-button @click="onEnroll" v-if="data.recruit_status+''==='0'">改为可招收</el-button>
        <el-button @click="onLookHistory">查看历史记录</el-button>
      </el-form-item>
    </el-form>

    <l-history-dialog :historyDialog="historyDialog" :historyData="historyDataArr" @close="onCloseDialog"/>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》 ';

export default {
  _config:{
    route:{
      path:"look",
      meta:{"title":"查看"},

    }
  },
  // import 引入的组件需要注入到对象中才能使用
  data() {
    // 这里存放数据
    return {
      data: {},
      historyDialog: false,
      historyDataArr: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$_register.get('api/recruit/account-manage/get-clues-detail', { params: { id: this.$route.query.id } }).then(res => {
        this.data = res.data.data
      })
    },
    onCloseDialog() {
      this.historyDialog = false
    },
    onLookHistory() {
      this.$_register.get('api/recruit/common/get-opt-history?tbl_name=account&tbl_id=' + this.$route.query.id).then(res => {
        res.data.data.list.length > 0 ? this.historyDataArr = JSON.parse(JSON.stringify(res.data.data.list).replace(/change_detail/g, 'change')) : this.historyDataArr = []
      })
      this.historyDialog = true
    },
    onEnroll() {
      this.$confirm('更改后,学生将会正常招收,请慎重!!!', '更改招收状态', {
        confirmButtonText: '仍要更改',
        cancelButtonText: '点错了',
        type: 'warning'
      }).then(() => {
        this.$_register.post('api/recruit/account-manage/chg-recruit-status', { id: this.$route.query.id }).then(res => {
          console.log()
          if (res.data.status === 0) {
            this.$message.success(res.data.msg)
            this.getData()
          }
        })
      }).catch(() => {
      });
    }
  }
}
</script>
<style scoped>
</style>
